const addScript2 = url => {
  const script = document.createElement("script")
  script.src = url
  document.getElementsByTagName(`head`)[0].appendChild(script)
}
const addScript3 = url => {
  const script = document.createElement("script")
  script.src = url
  document.getElementsByTagName(`body`)[0].appendChild(script)
}

exports.onRouteUpdate = s => {
  addScript2(
    `//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`
  )
  addScript3(process.env.GATSBY_BIRDEYE_CHATBOT_URL)
}
